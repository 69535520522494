.footer{
    margin-left: 25%;
    width: 50%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 2%;
    margin-top: 2%;
}

.startContact{
    margin-top: 2%;
    padding: 5%;
    background-color: #333333;
    color: white;
    display: grid; 
    place-items: center;
}

.location{
    margin-top: 5%;
    width: 40%;
    padding-top: 2%;
    border-width: 5px;
    border-style: solid;
    border-color: #d7ebee;
}