.description{
    font-size: smaller;
    width: 50%;
    position: relative;
    left: 25%;
}

.social{
    margin: 5% 1%;
    border-width: 2px;
    border-style: solid;
    border-color: #3838388a;
}