.title{
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    font-size: 51px;
    white-space: pre-line;
    color: #1c1c1c;
}


.image{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    z-index: -1;
}

.welcomeContainer{
    margin-left: 25%;
    width: 50%;
    background-color: #fafbfd;
    padding-top: 2%;
    padding-bottom: 4%;
    margin-bottom: 2%;
}

h3{
    font-size: 28px;
    color:#1c1c1c;
}

p{
    white-space: pre-line;
    
}


.pointsText{
    color: #fafbfd;
}

.pointsContainer{
    color: #fafbfd;
}

hr{
    width: 50%;
}

.containerOfThePoints{
    background-image: url(../images/home1.jpg);
    padding: 3%;
}

.points{
    margin: 0
}